/* eslint-disable jsx-a11y/anchor-is-valid */
import GoogleTranslate from "components/GoogleTranslate/GoogleTranslate";
import { Text, Button, Heading } from "./..";
import React, { useEffect } from "react";
import { useState } from "react";
import Modal from "react-modal";
import { Img } from "../../components";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header({ ...props }) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalComingIsOpen, setModalComingIsOpen] = React.useState(false);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const [isClicked, setIsClicked] = useState({});
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  function closeModal() {
    setModalComingIsOpen(false);
  }
  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "450px",
      width: "calc(100% - 20px)",
      borderRadius: "10px",
    },
  };

  const isMobileView = () => {
    if (window.innerWidth <= 1050) setIsMobile(true);
    else setIsMobile(false);
  };

  useEffect(() => {
    isMobileView();
    window.addEventListener("resize", isMobileView);
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      console.log("event", event);
      let id = event?.target?.id;
      if (
        typeof event?.target?.className === "string" &&
        !event?.target?.className?.includes("dropdownOuter")
      )
        setIsClicked({ [id]: false });
    };

    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  console.log("is clicked : ", isClicked);
  return (
    <>
      <header
        {...props}
        className={`${props.className} flex self-stretch mobileHeader fixed top-0 w-full z-10  justify-center items-center py-[20px] md:py-[15px] bg-gray-900_01`}
      >
        <div
          className={
            isMobile ? "" : `container-xs flex justify-center lg:p-2 md:p-5`
          }
        >
          <div
            className={
              isMobile
                ? ""
                : `flex w-full items-center justify-between gap-5 md:flex-col md:hidden lg:flex`
            }
          >
            {/* {!isMobile && ( */}
            <div className="logo-main">
              <Link to="/">
                <img
                  src={`${process.env.PUBLIC_URL}/images/img_group_29.png`}
                  alt="play"
                  className="h-[50px] w-auto md:h-[50px]"
                />
              </Link>
            </div>
            {/* )} */}
            <div
              className={
                isMobile ? "hideDesktop" : `flex items-center gap-5 md:flex-col`
              }
            >
              {isMobile && (
                <div className="menu-icon" onClick={toggleMenu}>
                  <div className={`line ${isOpen ? "open" : ""}`}></div>
                  <div className={`line ${isOpen ? "open" : ""}`}></div>
                  <div className={`line ${isOpen ? "open" : ""}`}></div>
                </div>
              )}
              <ul
                className={
                  isMobile
                    ? `menu ${isOpen ? "open" : ""}`
                    : `flex flex-wrap gap-[30px] items-center lg:gap-5 md:gap-5 mainMenu`
                }
              >
                <li>
                  <Link
                    className={`cursor-pointer ${
                      location.pathname === "/" ? "active" : ""
                    }`}
                    to="/"
                    onClick={() => {
                      window.scrollTo(0, 0);
                      setIsOpen(false);
                    }}
                  >
                    <Heading size="headingxxs" as="h4">
                      HOME
                    </Heading>
                  </Link>
                </li>
                <li>
                  <span
                    className={
                      location.pathname.includes("about-rie") ||
                      location.pathname.includes("sustainability")
                        ? "active"
                        : ""
                    }
                  >
                    <Heading
                      size="headingxxs"
                      onClick={() =>
                        setIsClicked((prev) => ({
                          about: !prev.about,
                        }))
                      }
                      as="h4"
                      id="about"
                      aria-expanded="true"
                      aria-haspopup="true"
                      className="dropdownOuter"
                    >
                      ABOUT RIE
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/menu-arrow.svg`}
                        alt="image"
                        className="inline ml-1 dropdownOuter"
                      />
                    </Heading>
                    <div
                      className={`absolute dropdownMenu right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ${
                        isClicked?.about ? "block" : "hidden"
                      }`}
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="menu-button"
                      tabIndex="-1"
                    >
                      <div className="py-1" role="none">
                        {/* <a
                          href="#"
                          className="block px-4 py-2 text-md text-gray-700"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          About
                        </a> */}
                        <Link
                          to={"/about-rie"}
                          className="block px-4 py-2 text-md text-gray-700"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-1"
                          onClick={() => setIsOpen(false)}
                        >
                          About
                        </Link>
                        <Link
                          to="/sustainability"
                          className="block px-4 py-2 text-md text-gray-700"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-1"
                          onClick={() => setIsOpen(false)}
                        >
                          Sustainability
                        </Link>
                      </div>
                    </div>
                  </span>
                </li>
                <li>
                  <Link
                    to={"/event"}
                    className={location.pathname === "/event" ? "active" : ""}
                  >
                    <Heading
                      size="headingxxs"
                      as="h4"
                      onClick={() =>
                        setIsClicked((prev) => ({
                          event: !prev.event,
                        }))
                      }
                      id="event"
                      aria-expanded="true"
                      aria-haspopup="true"
                      className="dropdownOuter"
                    >
                      EVENT
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/menu-arrow.svg`}
                        alt="image"
                        className="inline ml-1 dropdownOuter"
                      />
                    </Heading>
                  </Link>
                  <div
                    className={`absolute dropdownMenu right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ${
                      isClicked?.event ? "block" : "hidden"
                    }`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      {/* <a
                          href="#"
                          className="block px-4 py-2 text-md text-gray-700"
                          role="menuitem"
                          tabIndex="-1"
                          id="menu-item-0"
                        >
                          About
                        </a> */}
                      <span
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/speakers");
                          setIsOpen(false);
                        }}
                      >
                        SPEAKERS
                      </span>
                      <span
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/event", {
                            state: { divid: "socails" },
                          });
                          setIsOpen(false);
                        }}
                      >
                        SOCIALS
                      </span>
                      {/* <span
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/event", {
                            state: { divid: "rendezvous" },
                          });
                          setIsOpen(false);
                        }}
                      >
                        RIEMIX RENDEZVOUS
                      </span> */}
                      <span
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/event", {
                            state: { divid: "myeosection" },
                          });
                          setIsOpen(false);
                        }}
                      >
                        MYEO EXPERIENCES
                      </span>
                    </div>
                  </div>
                </li>

                {/* <li>
                  <span
                    
                    onClick={() => {
                      navigate("/event", {
                        state: { divid: "myeosection" },
                      });
                      setIsOpen(false);
                    }}
                    className="cursor-pointer"
                  >
                    <Heading size="headingxxs" as="h4">
                      MYEO
                    </Heading>
                  </span>
                </li> */}
                {/* <li>
                  <span
                   
                    onClick={() => {
                      navigate("/plan-your-trip", {
                        state: { divid: "accommodation" },
                      });
                      setIsOpen(false);
                    }}
                    className="cursor-pointer"
                    // className={location.pathname === "/event" ? "active" : ""}
                  >
                    <Heading size="headingxxs" as="h4">
                      HOTEL
                    </Heading>
                  </span>
                </li> */}
                <li style={{ position: "relative" }}>
                  <Link
                    to={"/plan-your-trip"}
                    // onClick={() => setIsOpen(false)}
                    className={
                      location.pathname.includes("plan-your-trip")
                        ? "active"
                        : ""
                    }
                  >
                    <Heading
                      size="headingxxs"
                      as="h4"
                      onClick={() =>
                        setIsClicked((prev) => ({
                          trip: !prev.trip,
                        }))
                      }
                      id="trip"
                      aria-expanded="true"
                      aria-haspopup="true"
                      className="dropdownOuter"
                    >
                      PLAN YOUR TRIP
                      <Img
                        src={`${process.env.PUBLIC_URL}/images/menu-arrow.svg`}
                        alt="image"
                        className="inline ml-1 dropdownOuter"
                      />
                    </Heading>
                  </Link>
                  <div
                    className={`absolute dropdownMenu right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none ${
                      isClicked?.trip ? "block" : "hidden"
                    }`}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="trip"
                    tabIndex="-1"
                  >
                    <div className="py-1" role="none">
                      <span
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/plan-your-trip", {
                            state: { divid: "venue" },
                          });
                          setIsOpen(false);
                        }}
                      >
                        VENUE
                      </span>
                      <span
                        to="/sustainability"
                        className="block px-4 py-2 text-md text-gray-700"
                        role="menuitem"
                        tabIndex="-1"
                        id="menu-item-1"
                        onClick={() => {
                          navigate("/plan-your-trip", {
                            state: { divid: "accommodation" },
                          });
                          setIsOpen(false);
                        }}
                      >
                        ACCOMODATIONS
                      </span>
                    </div>
                  </div>
                </li>

                <li className="text-center md:pb-6">
                  <Button
                    size="xs"
                    shape="square"
                    className={`min-w-[142px] md:mx-auto font-semibold  ${
                      isMobile ? `lg:text-[16px] h-[36px]` : `lg:text-[21px]`
                    }`}
                    onClick={() => {
                      handleRegsiter();
                    }}
                  >
                    REGISTER
                  </Button>
                </li>
              </ul>

              <ul className="flex flex-wrap gap-12">
                <li>
                  <Link className="cursor-pointer" to="#">
                    <Text size="texts" as="div">
                      <GoogleTranslate />
                    </Text>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <Modal
        isOpen={modalComingIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className="btnClose">
          <Img
            src={`${process.env.PUBLIC_URL}/images/close.svg`}
            alt="image"
            width="16px"
            height="16px"
          />
        </button>
        <div className="py-20 px-10">
          <Img
            src={`${process.env.PUBLIC_URL}/images/popup-logo.png`}
            alt="image"
            className="popupLogo"
          />
          <p className="text-[24px] text-center w-full mt-5">
            Opening at 18:30
          </p>
        </div>
      </Modal>
    </>
  );
}
