import { Link } from "react-router-dom";
import { Heading, Img } from "./..";
import React from "react";

export default function HomeSpreakers({
  titleText = "SPEAKERS",
  titleColor = "#2AA98B",
  statusText,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start  flex-1`}
    >
      <Heading
        as="h1"
        className="md:text-[34px] sm:text-[28px] "
        style={{ color: titleColor }}
      >
        {titleText}
      </Heading>
      <div className="flex w-[100%] flex-col items-center self-center sm-w-100">
        <h2 className="text-orange subheadingInner font-bold !font-bold mb-10 sm:mt-8">
          PLATINUM SPONSOR
        </h2>
        <a href="https://www.visitsaudi.com/en" target="_blank">
          {" "}
          <Img
            src={`${process.env.PUBLIC_URL}/images/saudi.svg`}
            alt="logo image"
            className=" max-w-[300px] sm:h-[150px] lg:h-auto md:h-auto"
          />
        </a>
        <h2 className="text-orange subheadingInner font-bold !font-bold mb-10 !mt-10">
          GOLD SPONSORS
        </h2>

        <div className="relative flex shakeMain !items-center !mt-1">
          <a href="https://shrewsburyindia.in/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/shre.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
          <a href="https://www.dezerv.in/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/dezerv.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
          <a href="https://frrimmigration.com/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/frr-logo.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>

          <a href="https://www.chestersindia.com/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/shesters.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
        </div>
        <div className="relative flex shakeMain  sm:!mt-1 ">
          <a
            href="https://dealer.porsche.com/in/bengaluru/en-GB"
            target="_blank"
          >
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/porsche.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
          <a href="https://www.maserati.com/in/en" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/masrati.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
          <a href="https://www.bajajcapital.com/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/bajaj-capital.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>

          <a href="https://www.redberyl.co/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/red-berly.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
        </div>

        <div className="relative flex shakeMain !items-center !justify-center sm:!mt-1 ">
          <a href="https://www.pemawellness.com/" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/pema-wellness.svg`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
          <a href="https://www.diageo.com/en" target="_blank">
            <Img
              src={`${process.env.PUBLIC_URL}/images/sponsors/diageo.png`}
              alt="logo image"
              className=" max-w-full lg:h-auto md:h-auto"
            />
          </a>
        </div>

        {/* <Img
          src={`${process.env.PUBLIC_URL}/images/img_group_29.png`}
          alt="logo image"
          className="h-[132px] w-full object-contain lg:h-auto md:h-auto"
        /> */}
      </div>
    </div>
  );
}
