import Footer from "components/Footer/Footer";
import Header from "components/Header";
import React, { Suspense, useState, useRef, useEffect, Fragment } from "react";
import { Img, Text, Heading, Button } from "../../components";

import { Helmet } from "react-helmet";
import EventSlider from "components/EventSlider/EventSlider";
import HoverImage from "components/hoverImage/hoverImg";
import { useLocation } from "react-router-dom";
import EventPopup from "components/Popup/EventPopup";
import { eventPopupFixture, socialPopupFixture } from "./utilities/fixture";
import Graphic from "./Graphic";
import Socials from "./Socials";
import SocailPopup from "components/Popup/SocailPopup";
import { Link } from "react-router-dom";

export default function Event() {
  const videoRef1 = useRef(null); // Reference to the video element
  const videoRef2 = useRef(null); // Reference to the video element
  const videoRef3 = useRef(null); // Reference to the video element

  const location = useLocation();
  const scrollInfo = location.state;

  const [isPlaying, setIsPlaying] = useState({
    video1: true,
    video2: true,
    video3: true,
  }); // State to manage play/pause
  const isiPhone = /iPhone/.test(navigator.userAgent);
  const [isHovered, setIsHovered] = useState(false);
  const [imgFileName, setImgFileName] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSocialModalOpen, setIsSocialModalOpen] = useState(false);
  const [popupData, setPopupData] = useState({});

  const handleRegsiter = () => {
    let url = "https://register.rie2025.com";
    window.open(url, "_blank", "noreferrer");
    // setModalComingIsOpen(true);
  };
  const handlePlayPause = (videoKey, videoRef) => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying({ ...isPlaying, [videoKey]: true });
    } else {
      video.pause();
      setIsPlaying({ ...isPlaying, [videoKey]: false });
    }
  };
  const toggleHover = (ind, hover = false) => {
    setImgFileName((prev) =>
      prev.map((c, i) =>
        i === ind ? (hover ? `essance-${i + 1}-hover` : `essance-${i + 1}`) : c
      )
    );
  };

  const handleModalOpen = (content = {}) => {
    console.log("called");
    setIsModalOpen(true);
    setPopupData(content);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPopupData({});
  };

  const socailModalOpen = (content = {}) => {
    console.log("called");
    setIsSocialModalOpen(true);
    setPopupData(content);
  };

  const socailModalClose = () => {
    setIsSocialModalOpen(false);
    setPopupData({});
  };

  useEffect(() => {
    if (scrollInfo?.divid) {
      const timer = setTimeout(() => {
        const id = scrollInfo.divid;
        const element = document.getElementById(id);

        if (element) {
          const rect = element.getBoundingClientRect();
          const scrollTop =
            window.pageYOffset || document.documentElement.scrollTop;
          const targetPosition = rect.top + scrollTop - 70;

          window.scrollTo({
            top: targetPosition,
            behavior: "smooth",
          });
        }
      }, 100); // Increased delay

      return () => clearTimeout(timer);
    }
  }, [scrollInfo]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(`essance-${i + 1}`);
    }
    setImgFileName(arr);
  }, []);

  return (
    <>
      <Helmet>
        <title>Event - Celebrating EO Bangalore&#39;s 25-Year Legacy</title>
        <meta
          name="description"
          content="Discover the essence of RIE, a curated 3-day event by EO Bangalore that blends world-class learning and networking with a rich cultural showcase. RIEMIX 2025 marks a remix of tradition and innovation."
        />
      </Helmet>

      <div className="flex justify-center self-stretch bg-black-900_7f py-[60px] lg:py-8 md:py-5 sm:py-4 video-container vdo-home ratio ratio-16x9">
        <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-32 self-stretch lg:gap-24 md:gap-24 md:p-5 sm:gap-16 py-144">
          <div className="flex w-[50%] flex-col items-center justify-end  lg:w-full md:w-full">
            <div className="">
              <video
                width="100%"
                className="video-background"
                ref={videoRef1}
                autoPlay
                playsInline
                loop
                muted
              >
                <source
                  src={`${process.env.PUBLIC_URL}/video/about-rie.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>

              <div className="videoDescp content">
                {isiPhone && (
                  <button
                    id="myBtn"
                    className="mt-[48px] lg:mt-[148px] md:mt-[78px]"
                    onClick={() => handlePlayPause("video1", videoRef1)}
                  >
                    <Img
                      src={`${process.env.PUBLIC_URL}/images/play.png`}
                      alt="play"
                      className="h-[50px] w-auto md:h-[50px]"
                    />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-10 pt-40 md:p-5">
        <Graphic />
        <div className="relative  z-1 mx-0">
          {/* <div className="max-w-[1702px] mx-auto flex w-full flex-col gap-10  self-stretch">
            <h3 class="mainHeadingInner text-purple mt-16 md:mt-5 ">
              SCHEDULE
            </h3>
            <EventSlider />
          </div> */}

          <div className="max-w-[1702px] mx-auto flex w-full flex-col ">
            <span className="halfCricleLineOne left upper_top ">
              <svg
                width="554"
                height="1107"
                viewBox="0 0 554 1107"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.5"
                  d="M0 1.07324C304.918 1.07324 552.103 248.258 552.103 553.176C552.103 858.094 304.918 1105.28 0 1105.28"
                  stroke="#EB3D71"
                  stroke-width="2"
                  className="path"
                />
              </svg>
            </span>
            <div class="flex flex-col items-center sm:pt-14" id="speakers">
              <div class="mr-[92px] ml-2 flex flex-col items-start gap-3 self-stretch lg:mx-0 md:mx-0">
                <h3 class="mainHeadingInner " style={{ color: "#EB3D71 " }}>
                  SPEAKERS
                </h3>
                <p class="subheadingInner">
                  Continuous learning and exchange of ideas are essential for
                  growth and innovation. RIEMIX features carefully curated
                  sessions designed to blend diverse perspectives, innovative
                  ideas and practical knowledge that leave you inspired.
                </p>
                <p class="subheadingInner">
                  Expect to engage in a range of interactive sessions,
                  insightful talks, and thought-provoking discussions. Each
                  session is an opportunity to reimagine concepts, explore new
                  approaches, and RIEMIX your understanding in a way that sparks
                  creativity and drives growth.
                </p>
                <div className="text-center w-full">
                  <Link to={"/speakers"} className="learnMore">
                    Learn More
                  </Link>
                </div>
                {/* <p class="subheadingInner">
                  Stay tuned for our specially curated lineup of speakers…
                </p> */}
              </div>
            </div>
            {/* <Socials /> */}
            {/* <div className="mt-[8px] relative mt-[40px] flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:mb-[30px] sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/baale-yele.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/baale-yele.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    socailModalOpen(socialPopupFixture.socail3);
                  }}
                />
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px] sm:px-4 sm:mb-[30px] flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/riemix-rendezvous.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/riemix-rendezvous.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    socailModalOpen(socialPopupFixture.socail4);
                  }}
                />
              </div>
            </div> */}
            <div className="py-14" id="socails">
              <h3 className="mainHeadingInner text-orange">SOCIALS</h3>
              <div className="mt-[8px] relative mt-[40px] sm:mt-[20px] flex items-end self-stretch md:flex-col w-full justify-between gap-x-20  event-pic-box">
                <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:px-4 flex-col text-center">
                  <HoverImage
                    defaultImage={`${process.env.PUBLIC_URL}/images/event/baale-yele.png`}
                    hoverImage={`${process.env.PUBLIC_URL}/images/event/baale-yele.png`}
                    altText="Feature Image"
                    className="object-cover lg:w-auto lg:h-auto  max-w-full"
                    onClick={() => {
                      socailModalOpen(socialPopupFixture.socail3);
                    }}
                  />
                </div>

                <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] sm:mb-[30px] sm:px-4 flex-col text-center">
                  <HoverImage
                    defaultImage={`${process.env.PUBLIC_URL}/images/event/primal-instinct.png`}
                    hoverImage={`${process.env.PUBLIC_URL}/images/event/primal-instinct.png`}
                    altText="Feature Image"
                    className="object-cover lg:w-auto lg:h-auto  max-w-full "
                    onClick={() => {
                      socailModalOpen(socialPopupFixture.socail1);
                    }}
                  />
                </div>
              </div>
              <div className="mt-[8px] relative mt-[40px] sm:mt-[10px] flex items-end self-stretch md:flex-col w-full justify-between gap-x-20  event-pic-box">
                <div className="flex flex-1 items-center self-end px-5  md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:mb-[30px] sm:px-4 flex-col text-center">
                  <HoverImage
                    defaultImage={`${process.env.PUBLIC_URL}/images/event/karmic-hills.png`}
                    hoverImage={`${process.env.PUBLIC_URL}/images/event/karmic-hills.png`}
                    altText="Feature Image"
                    className="object-cover lg:w-auto lg:h-auto  max-w-full "
                    onClick={() => {
                      socailModalOpen(socialPopupFixture.socail2);
                    }}
                  />
                </div>

                {/* <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px] sm:px-4 sm:mb-[30px] flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/riemix-rendezvous.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/riemix-rendezvous.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    socailModalOpen(socialPopupFixture.socail4);
                  }}
                />
              </div> */}
              </div>
            </div>
            {/* <div className="flex flex-col items-start pt-16" id="rendezvous">
              <h3 class="mainHeadingInner mb-5 " style={{ color: "#EB3D71 " }}>
                RIEMIX RENDEZVOUS
              </h3>
              <div
                className="socailBanners"
                style={{ backgroundColor: "#EB3D71" }}
              >
                <Img
                  src={`${process.env.PUBLIC_URL}/images/riemix-rendezvous.jpg`}
                  alt="socials banner"
                  className=" w-full object-contain lg:h-auto md:h-auto"
                />
                <div
                  className="hoverElements"
                  style={{ backgroundColor: "#EB3D71" }}
                >
                  <h3>RIEMIX Rendezvous</h3>
                  <h3>
                    10th January 2025 | 1:30pm - 2:30pm <br></br>
                    The Aviary, JW Marriott Golfshire
                  </h3>
                  <p className="subheadingInner">
                    Connections are at the core of the EO experience, and indeed
                    - RIEMIX is all about integration. Join us for RIEMIX
                    Rendezvous, a fun way to meet your fellow RIE attendees over
                    a casual lunch setting.
                  </p>
                  <h3>What to expect?</h3>
                  <p className="subheadingInner">
                    Sit with a group of fellow EO members at random, and turn
                    your lunch into an opportunity to connect with your peers.
                    Engage in lively discussions and share experiences over a
                    great meal. This lunch brings together new perspectives and
                    friendships, adding to the spirit of integration at RIEMIX.
                  </p>
                  <p className="subheadingInner">
                    Don't miss this unique opportunity to connect and build
                    lasting relationships.
                  </p>

                  <p className="subheadingInner">
                    Reserve your spot via the RIEMIX app.
                  </p>
                </div>
              </div>
            </div> */}
          </div>

          {/* <div
            className={`flex flex-col items-start gap-[54px] sm:gap-[27px] flex-1 py-14`}
          >
            <div className="flex w-[34%] min-w-[240px] flex-col items-center self-center sm-w-100">
            <Img
                src={`${process.env.PUBLIC_URL}/images/img_group_29.png`}
                alt="logo image"
                className="h-[132px] w-full object-contain lg:h-auto md:h-auto"
              /> 
               <Heading
                size="heading2xl"
                as="h1"
                className="relative mt-[-44px] sm:mt-[-24px] !text-teal-400_01 lg:text-5xl sm:text-3xl progress_heading"
              >
                <span className="text-white-a700 in_progress">IN PROGRESS</span>
                <span className="text-pink-a200 animate-pulse">.</span>
                <span className="text-amber-300_01 animate-pulse delay-300">
                  .
                </span>
                <span className="text-teal-400_01 animate-pulse delay-700">
                  .
                </span>
              </Heading> 
            </div>
          </div> */}

          <div
            id="myeosection"
            className="max-w-[1702px] mx-auto flex w-full flex-col  gap-5 self-stretch"
          >
            <div className="flex flex-col items-start gap-6">
              <h1 className=" mainHeadingInner ">MYEO EXPERIENCES</h1>
              <h2 className="subheadingInner">
                Discover the essence of Bengaluru through specially curated MyEO
                events, designed to show you the hidden and the prominent gems
                of our city.
              </h2>
            </div>

            <div className="mt-[8px] relative flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/golf.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/golf-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img1);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  RIEMIX Open Golf Tournament
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 10 AM - 4 PM
                </p>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px] sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Tokyo.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Tokyo-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img2);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  One Night In Tokyo
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 6 PM - 10 PM
                </p>
              </div>
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Harvest.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Harvest-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img3);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  The Modern Harvest Supper
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 6∶30 PM - 10∶30 PM
                </p>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/conscious.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/conscious-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img4);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  Conscious Connected Breathwork
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 5 PM - 7 PM
                </p>
              </div>
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Sport.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Sport-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img5);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  The Business Of Sport
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 3 PM - 6 PM
                </p>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/bial.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/bial-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img6);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  A Walk In The Park - A BTS Visit To BIAL
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 3 PM - 6 PM
                </p>
              </div>
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              {/* <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Coffee.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Coffee-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img7);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  By 2 Coffee – A Brewing Workshop
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 3 PM - 6 PM
                </p>
              </div> */}

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/dosa-new.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/dosa-new-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img8);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">Death By Dosa</h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 2∶30 PM - 6 PM
                </p>
              </div>

              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Tipsy.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Tipsy-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img9);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">Tipsy Tour</h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 5 PM - 9.30 PM
                </p>
              </div>
            </div>

            {/* <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Maharajah.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Maharajah-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img10);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  The Mysore Maharajah
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 8 AM - 9 PM
                </p>
              </div>
            </div> */}

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Tipus.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Tipus-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img11);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  Tipu’s Trail - A Walk Through History
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 10∶30 AM - 2∶30 PM
                </p>
              </div>
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Partner-Yoga.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Partner-Yoga-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img13);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  Partner Yoga Workshop
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  11th January 2025 | 7 AM - 8 AM
                </p>
              </div>

              {/* <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Masterclass.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Masterclass-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img12);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  Masterclass With Magnolia Bakery
                </h4>
                <p className="text-white-a700  text-2xl sm:text-[16px]">
                  9th January 2025 | 3∶30 PM - 5 PM | 5∶30 PM - 7 PM
                </p>
              </div> */}
            </div>

            <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Animal-Flow.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Animal-Flow-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img14);
                  }}
                />
                <h4 className="subheadingInner sm:mt-[10px]">
                  Animal Flow Workshop
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  10th January 2025 | 7 AM - 8∶30 AM
                </p>
              </div>
            </div>

            {/* <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
              <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
                <HoverImage
                  defaultImage={`${process.env.PUBLIC_URL}/images/event/Bordeaux.png`}
                  hoverImage={`${process.env.PUBLIC_URL}/images/event/Bordeaux-hover.png`}
                  altText="Feature Image"
                  className="object-cover lg:w-auto lg:h-auto  max-w-full "
                  onClick={() => {
                    handleModalOpen(eventPopupFixture.img15);
                  }}
                />

                <h4 className="subheadingInner sm:mt-[10px]">
                  Bordeaux à Bangalore
                </h4>
                <p className="text-white-a700 text-2xl sm:text-[16px]">
                  9th January 2025 | 7 PM - 10 PM
                </p>
              </div>
            </div> */}

            <div>
              <h4 className="yellowHeading text-orange">NOTE</h4>

              <ul className="yellowBullet pl-0">
                <li>Register for MyEO experiences on the RIEMIX App</li>
                <li>All bookings are non-transferable and non-refundable.</li>
                <li>
                  Transportation will be arranged from your respective hotels to
                  each MyEO event.
                </li>
                <li>
                  The timings mentioned for each event indicates departure time
                  from the hotel until the end of the event. In case you are
                  arriving at the event venue directly please let us know.
                </li>
                <li>
                  Travel time to the venue can vary between 30 to 90 minutes
                  depending on the event.
                </li>
                <li>
                  Some evening MyEO events may be in conflict with the RIEMIX
                  Welcome Dinner.
                </li>
                <li>
                  {" "}
                  In case you are unable to register for a MyEO event, please
                  join the waitlist via the App.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <EventPopup
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={popupData}
        handleModalClose={handleModalClose}
      />
      <SocailPopup
        isOpen={isSocialModalOpen}
        setIsOpen={setIsSocialModalOpen}
        data={popupData}
        handleModalClose={socailModalClose}
      />

      <Footer />
    </>
  );
}
