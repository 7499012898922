import React from "react";

export default function Graphic() {
  return (
    <>
      <span className="halfCricleLineTwo  ">
        <svg
          width="518"
          height="1035"
          viewBox="0 0 518 1035"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="line"
            d="M518 1034.43C232.6 1034.43 1.23694 803.071 1.23694 517.671C1.23694 232.271 232.6 0.908203 518 0.908203"
            stroke="#ECC846"
          />
          <path
            class="line"
            d="M518 946.147C281.359 946.147 89.5239 754.312 89.5239 517.671C89.5239 281.03 281.359 89.1953 518 89.1953"
            stroke="#ECC846"
          />
        </svg>
      </span>

      <span className="arrowDownLeftRed">
        <svg
          width="151"
          height="857"
          viewBox="0 0 151 357"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.4683 48.3972L66.3947 1.78796H2.54199L34.4683 48.3972Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.4683 135.083L66.3947 88.4739H2.54199L34.4683 135.083Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.4683 221.769L66.3947 175.16H2.54199L34.4683 221.769Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M116.187 95.0061L148.114 48.397H84.261L116.187 95.0061Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M116.187 181.692L148.114 135.083H84.261L116.187 181.692Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M116.187 268.378L148.114 221.769H84.261L116.187 268.378Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M34.4683 308.455L66.3947 261.846H2.54199L34.4683 308.455Z"
            stroke="#29A98C"
            stroke-width="2"
          />
          <path
            class="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M116.187 355.064L148.114 308.455H84.261L116.187 355.064Z"
            stroke="#29A98C"
            stroke-width="2"
          />
        </svg>
      </span>

      <span className="arrowDownLeftRed bottom">
        <svg
          width="131"
          height="883"
          viewBox="0 0 131 383"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6621 189.853L1.95898 230.297H57.3653L29.6621 189.853Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6621 114.142L1.95898 154.586H57.3653L29.6621 114.142Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6621 38.4312L1.95898 78.875H57.3653L29.6621 38.4312Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.406 153.195L73.7029 193.639H129.109L101.406 153.195Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.406 77.4839L73.7029 117.928H129.109L101.406 77.4839Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.406 1.77295L73.7029 42.2168H129.109L101.406 1.77295Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6621 341.275L1.95898 381.718L57.3653 381.719L29.6621 341.275Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.6621 265.564L1.95898 306.008H57.3653L29.6621 265.564Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.405 304.617L73.7019 345.061H129.108L101.405 304.617Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
          <path
            className="path2"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.405 228.906L73.7019 269.349H129.108L101.405 228.906Z"
            stroke="#ED3B70"
            stroke-width="2"
          />
        </svg>
      </span>

      <span className="arrowDownLeftRed right">
        <svg
          width="150"
          height="358"
          viewBox="0 0 150 358"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.9593 48.5962L65.8856 1.98706H2.03296L33.9593 48.5962Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.9593 132.016L65.8856 85.407H2.03296L33.9593 132.016Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.9593 215.436L65.8856 168.827H2.03296L33.9593 215.436Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.9593 308.654L65.8856 262.045H2.03296L33.9593 308.654Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.678 95.2051L147.605 48.5959H83.752L115.678 95.2051Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.678 178.625L147.605 132.016H83.752L115.678 178.625Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.678 262.045L147.605 215.436H83.752L115.678 262.045Z"
            stroke="#ECC846"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M115.678 355.264L147.605 308.655H83.752L115.678 355.264Z"
            stroke="#ECC846"
            stroke-width="2"
          />
        </svg>
      </span>

      <span className="arrowDownLeftRed right two">
        <svg
          width="132"
          height="384"
          viewBox="0 0 132 384"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.642 193.564L129.345 153.12H73.9387L101.642 193.564Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.642 269.275L129.345 228.831H73.9387L101.642 269.275Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.642 344.985L129.345 304.542H73.9387L101.642 344.985Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.8989 230.222L57.6021 189.778H2.1958L29.8989 230.222Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.8989 305.933L57.6021 265.489H2.1958L29.8989 305.933Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.8989 381.644L57.6021 341.2H2.1958L29.8989 381.644Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.642 42.1421L129.345 1.69824H73.9387L101.642 42.1421Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M101.642 117.853L129.345 77.4092H73.9387L101.642 117.853Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.8989 78.7998L57.6021 38.3564H2.1958L29.8989 78.7998Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
          <path
            className="path"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.8989 154.511L57.6021 114.067H2.1958L29.8989 154.511Z"
            stroke="#2AA98B"
            stroke-width="2"
          />
        </svg>
      </span>
    </>
  );
}
