import React, { Fragment, useState } from "react";
import { Img, Text, Heading, Button } from "../components";
import Footer from "components/Footer/Footer";
import Header from "components/Header";
import { Link, useNavigate } from "react-router-dom";
import SpeakersProfile from "components/SpeakersProfile";
import { speakersFixture } from "components/SpeakersProfile/utilities/fixtures";

export default function Speakers() {
  const [activeIndex, setActiveIndex] = useState(null);
  const navigate = useNavigate();
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <>
      <div className="w-full bg-[url(/public/images/arrow-yellow.png)] bg-arrow-green bg-no-repeat">
        <div className="w-full bg-[url(/public/images/arrow-green.png)] bg-arrow-green bg-no-repeat">
          <div className="container-xs mt-[84px] md:mt-[20px] pt-[60px] pl-20 pr-20 sm:pl-4 sm:pr-4 ">
            <h3
              class="mainHeadingInner "
              style={{ color: "rgb(235, 61, 113)" }}
            >
              SPEAKERS
            </h3>
            <p class="subheadingInner sm:mt-4 !text-amber-300 !font-bold">
              THE POWER OF HUMAN WILL
            </p>

            <p className="subheadingInner sm:mt-4">
              In a world of dizzying change, one steady fulcrum remains: the
              power of human will.
            </p>
            <p className="subheadingInner sm:mt-4">
              It drives us to envision the new, confront crises, seize and
              create opportunities, foster cooperation, expand horizons, revive
              hope, break ground, build, and lead. At RIEMIX, we celebrate this
              extraordinary force. Over two transformative days and through 20
              life-altering conversations, an extraordinary array of minds,
              grit-filled entrepreneurs, curious strategists, ingenious
              scientists, and visionary leaders from all walks of life, will
              inspire us with their journeys of reinvention and rethinking the
              possible.
            </p>
            <p className="subheadingInner sm:mt-4">
              Come curious. Leave inspired.
            </p>
            <p className=" text-[44px] md:text-[34px] sm:text-[24px] mb-10 mt-10 !text-amber-300 !font-bold">
              Visionaries | Changemakers | Innovators | Leaders | Pathfinders
            </p>

            <div className="grid grid-cols-4 sm:grid-cols-1 md:grid-cols-4 tablet:grid-cols-2   gap-2 ">
              {speakersFixture.map((speaker, index) => (
                <Fragment key={index}>
                  <div className="p-4 justify-center mb-6 sm:mb-4 ">
                    <SpeakersProfile data={speaker} />
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
