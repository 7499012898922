export const speakersFixture = [
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-1.jpg`,
    className: "type1",
    heading: "AJIT DOVAL",
    description: ` Ajit Doval, India's National Security Advisor, is renowned for his expertise in intelligence, counter-terrorism and diplomacy. He has played a pivotal role in shaping India's national security policies, including key initiatives such as the abrogation of Article 370 and managing critical emergencies.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-2.jpg`,
    className: "type1",
    heading: "N. R. NARAYANA MURTHY",
    description: ` Narayana Murthy is the visionary behind Infosys. His vision transformed India into a global IT powerhouse. A champion of ethical leadership and innovation, he redefined corporate success by blending profit with purpose.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-3.jpg`,
    className: "type1",
    heading: "SRIKANTH BOLLA",
    description: `Srikanth Bolla is an inspiring entrepreneur and the founder of Bollant Industries. Blind since birth, he exemplifies resilience and determination, leading a sustainable enterprise that empowers differently-abled individuals through innovation and inclusivity.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-4.jpg`,
    className: "type1",
    heading: "RAJKUMMAR RAO",
    description: `Rajkummar Rao is an acclaimed Indian actor known for his transformative performances and versatility. Rising from humble beginnings, he combines authenticity with powerful storytelling, using cinema as a medium for social commentary and inspiring meaningful change in society.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-5.jpg`,
    className: "type1",
    heading: "KAREENA KAPOOR KHAN",
    description: `Kareena Kapoor Khan is celebrated for her iconic roles and her candid approach to life. She has seamlessly blended her artistic career with family life, becoming a champion of authenticity and individuality. `,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-6.jpg`,
    className: "type1",
    heading: "SAIF ALI KHAN",
    description: `With a career spanning decades, Saif Ali Khan has consistently reinvented himself, delivering critically acclaimed performances across genres. A modern family man and entrepreneur, his journey reflects creativity, adaptability, and personal growth.`,
  },

  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-7.jpg`,
    className: "type1",
    heading: "DR PRAMOD VARMA",
    description: `Dr. Pramod Varma is the visionary architect behind Aadhaar, the world’s largest biometric identity platform, and India Stack, a groundbreaking digital framework transforming financial inclusion and governance. With over 30 years of expertise, he has been instrumental in creating open digital ecosystems that empower millions and drive innovation.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-8.jpg`,
    className: "type1",
    heading: "KUNAL SHAH",
    description: `Kunal Shah is the visionary founder of CRED, a fintech platform redefining financial wellness in India. A serial entrepreneur and thought leader, he is known for his ability to challenge conventional thinking, disrupt industries and create consumer-centric solutions.`,
  },

  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-9.jpg`,
    className: "type2",
    heading: "DR DAVID PUTRINO",
    description: `Dr. David Putrino is a renowned neuroscientist and physical therapist at the forefront of healthcare innovation. As the Director of Rehabilitation Innovation at Mount Sinai Health System, he leads the development of cutting-edge technology solutions to improve healthcare accessibility and outcomes.`,
  },

  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-10.jpg`,
    className: "type2",
    heading: "ANU AGA",
    description: `Anu Aga is a pioneer in business leadership and philanthropy. She rebuilt Thermax into a global leader, demonstrating remarkable resilience and vision in the face of personal loss and professional challenges. A passionate advocate for corporate responsibility and inclusive growth, Anu continues to inspire many with her unwavering commitment to driving meaningful change.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-11.jpg`,
    className: "type2",
    heading: "MEHER PUDUMJEE",
    description: `Meher Pudumjee has carried forward Thermax’s legacy with a focus on innovation and sustainability. Beyond the boardroom, she is a passionate advocate for women’s leadership and community development, blending empathy with strategic vision.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-12.jpg`,
    className: "type2",
    heading: "VIR DAS",
    description: `Vir Das is an internationally acclaimed comedian and actor who has redefined the art of storytelling through humour. With Netflix specials and an Emmy nomination, his unique perspective blends cultural commentary with wit and intelligence.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-13.jpg`,
    className: "type2",
    heading: "DEVIKA MALIK",
    description: `In addition to being a Paralympian, Devika Malik is a disability rights activist and the co-founder of Wheeling Happiness. With a focus on empowerment and inclusion, she has championed policies and initiatives that uplift marginalized communities.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-14.jpg`,
    className: "type2",
    heading: "BHAVISH AGGARWAL",
    description: `As co-founder and CEO of Ola, Bhavish Aggarwal has led India’s ride-hailing revolution and spearheaded the transition to sustainable mobility with Ola Electric. His approach to urban transportation has gained global recognition as he continues to innovate and disrupt the realms of EVs, AI, and sustainable technology.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-15.jpg`,
    className: "type2",
    heading: "DR DEEPA MALIK",
    description: `Dr. Deepa Malik is an inspiring force in Indian sports and a strong voice for inclusivity in athletics. She serves as the President of the Paralympic Committee of India and, as the country’s first female Paralympic medalist, she has broken many barriers. Her story of resilience exemplifies grace under fire.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-16.jpg`,
    className: "type2",
    heading: "SHRI P. CHIDAMBARAM",
    description: `P. Chidambaram, a distinguished politician, lawyer, and former Finance Minister of India, is widely recognized for his role in driving economic reforms. He was instrumental in shaping India’s fiscal policies, driving growth, and fostering the nation’s global economic integration during his tenure.`,
  },

  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-17.jpg`,
    className: "type3",
    heading: "NEELKANTH MISHRA",
    description: `A globally respected economist, Neelkanth Mishra brings a nuanced understanding of macroeconomic trends and financial systems. With extensive experience in research and policy advisory, he has become a vital voice in analyzing India’s economic landscape.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-18.jpg`,
    className: "type3",
    heading: "NITHIN KAMATH",
    description: `Nithin Kamath is the founder and CEO of Zerodha, India’s largest stockbroking app and platform. A visionary entrepreneur and sustainability advocate, he champions financial inclusion and integrates ESG principles, redefining finance for long-term impact and growth.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-19.jpg`,
    className: "type3",
    heading: "JAY KOTAK",
    description: `Jay Kotak represents the next generation of leadership at Kotak Mahindra Bank. With a strong educational foundation from Columbia University and Harvard Business School, he is redefining the bank’s future with a focus on innovation, digital transformation, and customer-centricity.`,
  },
  // {
  //   defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-20.jpg`,
  //   className: "type3",
  //   heading: "KALLI PURIE",
  //   description: `Kallie Purie is a transformative leader in Indian media, steering the India Today Group into the digital age. She has redefined content creation and distribution by combining journalistic integrity with innovation. Her focus on blending traditional media values with modern technological advances has positioned her as a dynamic force in the industry.`,
  // },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-21.jpg`,
    className: "type3",
    heading: "SACHIN PILOT",
    description: `Sachin Pilot is a dynamic political leader who has successfully navigated the challenges of legacy and innovation. A former Union Minister and current Member of the Rajasthan Legislative Assembly, he is known for his dynamic leadership and youth-centric vision. His leadership reflects a deep commitment to balancing tradition with modernity.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-22.jpg`,
    className: "type3",
    heading: "SMT SMRITI IRANI",
    description: `Smriti Irani is a prominent Indian politician known for her impactful leadership and dedication to social welfare. She has been a vocal advocate for women's empowerment, child development and education, driving transformative initiatives that have shaped public policy and progress.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-23.jpg`,
    className: "type3",
    heading: "MANU S. PILLAI",
    description: `Manu S Pillai is a historian and author, known for his engaging narratives and fresh perspectives on the subcontinent’s past. With bestselling books such as The Ivory Throne, Rebel Sultans, and The Courtesan and The Mahatma, he has brought history to life for the younger generations.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-24.jpg`,
    className: "type3",
    heading: "MARK McCAUGHREAN",
    description: `Mark McCaughrean is a renowned astrophysicist and former Senior Advisor at the European Space Agency. With a key role in the groundbreaking James Webb Space Telescope project, his work has significantly advanced our understanding of the cosmos.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-25.jpg`,
    className: "type4",
    heading: "SAMANTHA RUTH PRABHU",
    description: `Samantha is a celebrated actor whose versatile performances have captivated audiences across Indian cinema. She has ventured into entrepreneurship with her brand Saaki, and several other investments. Samantha inspires millions through her authenticity, resilience, and commitment to creating a positive impact both on-screen and in business.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-26.jpg`,
    className: "type4",
    heading: "BASSAM ARAMIN",
    description: `Once a member of the Palestinian resistance, Bassam Aramin embraced non-violence after the tragic loss of his young daughter in the Israel-Palestine conflict. He now devotes his life to advocating for peace and reconciliation in the region.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-27.jpg`,
    className: "type4",
    heading: "ROBI DAMELIN",
    description: `Robi Damelin, an Israeli peace activist, turned her grief over losing her son in the Israeli-Palestinian conflict into a mission for peace. A leading voice for reconciliation, she advocates for forgiveness, dialogue, and nonviolence, inspiring understanding and healing in conflict resolution efforts worldwide.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-28.jpg`,
    className: "type4",
    heading: "RAVICHANDRAN ASHWIN",
    description: `Ravichandran Ashwin is an accomplished Indian cricketer and one of the world’s premier all-rounders. Known for his masterful off-spin bowling and tactical acumen, he has played a pivotal role in India’s cricketing success across all formats of the game.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-29.jpg`,
    className: "type4",
    heading: "RAHUL MATTHAN",
    description: `Rahul Matthan is a leading technology lawyer and author. A partner at Trilegal, he specializes in privacy, data protection, and technology law. Rahul is the author of Privacy 3.0 and he also writes Ex Machina for Mint exploring the evolution of digital privacy.`,
  },
  {
    defaultImg: `${process.env.PUBLIC_URL}/images/speakers/speaker-30.jpg`,
    className: "type4",
    heading: "VIKRAM VAIDYANATHAN",
    description: `Vikram Vaidyanathan is a visionary investor driving investments in fintech, edtech, SaaS, and D2C sectors. He has supported transformative companies like Razorpay and Dailyhunt, shaping India's dynamic startup ecosystem with innovation and strategic insight.`,
  },
];
