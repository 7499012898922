import { Heading, Img, Text } from "components";
import React from "react";
import { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import HoverImage from "components/hoverImage/hoverImg";
export default function SocailPopup({
  isOpen = false,
  setIsOpen = () => {},
  handleModalClose = () => {},
  data = {},
}) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        className={"myeoPopup ReactModal__ContentCustom"}
        onRequestClose={handleModalClose}
        contentLabel="Example Modal"
        role="document"
        preventScroll={false}
      >
        <button onClick={handleModalClose} className="btnClose ">
          <Img
            src={`${process.env.PUBLIC_URL}/images/close1.svg`}
            alt="image"
            width="16px"
            height="16px"
          />
        </button>
        <div className="myeopopupBox">
          <div className="aboutBg" style={{ position: "initial" }}>
            {/* <div className="max-w-[1440px] lg:max-w-[1250px]  positon mb-[50px] md:mb-[30px] md:flex-col mx-auto flex w-full gap-5 self-stretch  z-1">
              <div className="arrowDownLeftRed left">
                <svg
                  width="68"
                  height="192"
                  viewBox="0 0 68 192"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 96.3053L65.3647 76.2422H37.8789L51.6218 96.3053Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 133.864L65.3647 113.801H37.8789L51.6218 133.864Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 171.423L65.3647 151.359H37.8789L51.6218 171.423Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 114.49L29.7748 94.4268H2.28906L16.032 114.49Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 152.049L29.7748 131.986H2.28906L16.032 152.049Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 189.607L29.7748 169.544H2.28906L16.032 189.607Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 21.1882L65.3647 1.125H37.8789L51.6218 21.1882Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M51.6218 58.7468L65.3647 38.6836H37.8789L51.6218 58.7468Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 39.3738L29.7748 19.3105H2.28906L16.032 39.3738Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    fill-rule="evenodd"
                    className="path"
                    clip-rule="evenodd"
                    d="M16.032 76.9324L29.7748 56.8691H2.28906L16.032 76.9324Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                </svg>
              </div>

              <div className="arrowDownLeftRed right">
                <svg
                  width="68"
                  height="209"
                  viewBox="0 0 73 209"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 104.253L2.30469 126.105H32.2417L17.2732 104.253Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 63.3447L2.30469 85.1971H32.2417L17.2732 63.3447Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2731 22.4375L2.30469 44.2899H32.2416L17.2731 22.4375Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 84.4463L41.0703 106.299L71.0072 106.299L56.0388 84.4463Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 43.5391L41.0703 65.3915H71.0072L56.0388 43.5391Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0388 2.63086L41.0703 24.4833H71.0072L56.0388 2.63086Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 186.069L2.30469 207.922H32.2417L17.2732 186.069Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17.2732 145.161L2.30469 167.014L32.2417 167.014L17.2732 145.161Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0387 166.262L41.0703 188.114H71.0072L56.0387 166.262Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                  <path
                    className="path2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M56.0387 125.354L41.0703 147.206H71.0072L56.0387 125.354Z"
                    stroke="#ECC846"
                    strokeWidth="2"
                  />
                </svg>
              </div>
            </div> */}

            <div className="relative ">
              <div className="max-w-[1440px] lg:max-w-[1250px]  mx-auto flex flex-col   gap-3 self-stretch  z-1">
                {/* <div
                  className="arrowDownLeftRed left"
                  style={{ bottom: "0px", top: "auto" }}
                >
                  <svg
                    width="100"
                    height="383"
                    viewBox="0 0 100 383"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 104.277L2.30469 126.108H32.2417L17.2732 104.277Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 63.4092L2.30469 85.2403H32.2417L17.2732 63.4092Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 84.4902L41.0684 106.321L71.0052 106.321L56.0369 84.4902Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 43.623L41.0684 65.4541H71.0052L56.0369 43.623Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0369 2.75488L41.0684 24.586H71.0052L56.0369 2.75488Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 186.015L2.30469 207.846H32.2417L17.2732 186.015Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M17.2732 145.146L2.30469 166.978L32.2417 166.978L17.2732 145.146Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0367 166.227L41.0684 188.058H71.0052L56.0367 166.227Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                    <path
                      className="path2"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M56.0367 125.357L41.0684 147.189H71.0052L56.0367 125.357Z"
                      stroke="#ECC846"
                      strokeWidth="2"
                    />
                  </svg>
                </div> */}
                {/* <p className="subheadingInner">Tee off at the beautiful Prestige Golfshire, where the stunning greens overlooking the verdant Nandi Hills and the glorious Bangalore weather provide the perfect setting for an unforgettable day. </p>
                        <p className="subheadingInner">
                            Join your fellow EO members on one of the country’s most prestigious golf courses to forge new friendships, share laughs, and enjoy a leisurely yet spirited game of golf together followed by fellowship at the 19th hole....
                        </p> */}

                <div
                  className="hoverElements socailsmodalDetails"
                  //   style={{ backgroundColor: bgColor }}
                >
                  <h3>{data.title}</h3>
                  {data.date && <h3>{data.date}</h3>}
                  {data?.subhead && <h3>{data.subhead}</h3>}
                  {data?.description?.map((text, idx) => (
                    <p key={idx} className="subheadingInner">
                      {text}
                    </p>
                  ))}
                  {data?.titleTwo && <h3 className="mt-3">{data.titleTwo}</h3>}
                  {data?.descriptionTwo?.map((text, idx) => (
                    <p key={idx} className="subheadingInner">
                      {text}
                    </p>
                  ))}
                  {data?.descriptionThree?.map((text, idx) => (
                    <p key={idx} className="subheadingInner">
                      {text}
                    </p>
                  ))}

                  {data?.descriptionFour?.map((text, idx) => (
                    <p key={idx} className="subheadingInner">
                      {text}
                    </p>
                  ))}
                  {data?.dressCodeLabel ? (
                    <>
                      <h3>Dress Code</h3>
                      <p className="subheadingInner">
                        {data.dressCodeLabel && (
                          <span className="font-bold">
                            {data.dressCodeLabel}
                          </span>
                        )}
                        : {data.dressCode || data.dressCodeDetails}
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
