import { Heading, Img } from "./..";
import React from "react";

export default function SpeakersProfile({ data = {} }) {
  return (
    <div className={`${data?.className} speaker-card`}>
      <div className="speaker-img">
        <Img
          src={data?.defaultImg}
          alt="logo image"
          className=" max-w-full lg:h-auto md:h-auto"
        />
        <div className="overlaydiv">
          <div className="speaker-descp">
            <h4>{data?.heading}</h4>
            <p>{data?.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
