import React, { useEffect, useState } from "react";
import { Img, Text, Heading, Button } from "../components";
import Footer from "components/Footer/Footer";
import { Link, useNavigate } from "react-router-dom";
import HoverImage from "components/hoverImage/hoverImg";
import EventPopup from "components/Popup/EventPopup";
import { eventPopupFixture } from "./event/utilities/fixture";

export default function MyEo() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [imgFileName, setImgFileName] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popupData, setPopupData] = useState({});
  const navigate = useNavigate();

  const handleModalOpen = (content = {}) => {
    console.log("called");
    setIsModalOpen(true);
    setPopupData(content);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setPopupData({});
  };
  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const toggleHover = (ind, hover = false) => {
    setImgFileName((prev) =>
      prev.map((c, i) =>
        i === ind ? (hover ? `essance-${i + 1}-hover` : `essance-${i + 1}`) : c
      )
    );
  };
  useEffect(() => {
    let arr = [];
    for (let i = 0; i < 10; i++) {
      arr.push(`essance-${i + 1}`);
    }
    setImgFileName(arr);
  }, []);
  return (
    <>
      <div
        id="myeosection"
        className="max-w-[1702px] mx-auto flex w-full flex-col gap-5 self-stretch container-xs mt-[84px] md:mt-[20px] pt-[60px] pl-20 pr-20 sm:pl-5 sm:pr-5 "
      >
        <div className="flex flex-col items-start gap-6">
          <h1 className=" mainHeadingInner ">MYEO EXPERIENCES</h1>
          <h2 className="subheadingInner">
            Discover the essence of Bengaluru through specially curated MyEO
            events, designed to show you the hidden and the prominent gems of
            our city.
          </h2>
        </div>

        <div className="mt-[8px] relative flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/golf.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/golf-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img1);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              RIEMIX Open Golf Tournament
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 10 AM - 4 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px] sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Tokyo.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Tokyo-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img2);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">One Night In Tokyo</h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 6 PM - 10 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Harvest.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Harvest-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img3);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              The Modern Harvest Supper
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 6∶30 PM - 10∶30 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/conscious.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/conscious-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img4);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              Conscious Connected Breathwork
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 5 PM - 7 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Sport.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Sport-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img5);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              The Business Of Sport
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 3 PM - 6 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/bial.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/bial-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img6);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              A Walk In The Park - A BTS Visit To BIAL
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 3 PM - 6 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Coffee.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Coffee-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img7);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              By 2 Coffee – A Brewing Workshop
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 3 PM - 6 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/dosa-new.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/dosa-new-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img8);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">Death By Dosa</h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 2∶30 PM - 6 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Tipsy.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Tipsy-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img9);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">Tipsy Tour</h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 5 PM - 9.30 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Maharajah.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Maharajah-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img10);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              The Mysore Maharajah
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 8 AM - 9 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Tipus.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Tipus-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img11);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              Tipu’s Trail - A Walk Through History
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 10∶30 AM - 2∶30 PM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Masterclass.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Masterclass-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img12);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              Masterclass With Magnolia Bakery
            </h4>
            <p className="text-white-a700  text-2xl sm:text-[16px]">
              9th January 2025 | 3∶30 PM - 5 PM | 5∶30 PM - 7 PM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Partner-Yoga.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Partner-Yoga-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img13);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              Partner Yoga Workshop
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              11th January 2025 | 7 AM - 8 AM
            </p>
          </div>

          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px] md:mb-0 sm:mb-[10px]  sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Animal-Flow.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Animal-Flow-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img14);
              }}
            />
            <h4 className="subheadingInner sm:mt-[10px]">
              Animal Flow Workshop
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              10th January 2025 | 7 AM - 8∶30 AM
            </p>
          </div>
        </div>

        <div className="mt-[8px] relative  flex items-end self-stretch md:flex-col w-full justify-between gap-x-20 event-pic-box">
          <div className="flex flex-1 items-center self-end px-5 md:self-stretch md:px-5 mb-[50px]  md:mb-10 sm:px-4 flex-col text-center">
            <HoverImage
              defaultImage={`${process.env.PUBLIC_URL}/images/event/Bordeaux.png`}
              hoverImage={`${process.env.PUBLIC_URL}/images/event/Bordeaux-hover.png`}
              altText="Feature Image"
              className="object-cover lg:w-auto lg:h-auto  max-w-full "
              onClick={() => {
                handleModalOpen(eventPopupFixture.img15);
              }}
            />

            <h4 className="subheadingInner sm:mt-[10px]">
              Bordeaux à Bangalore
            </h4>
            <p className="text-white-a700 text-2xl sm:text-[16px]">
              9th January 2025 | 7 PM - 10 PM
            </p>
          </div>
        </div>

        <div>
          <h4 className="yellowHeading text-orange">NOTE</h4>

          <ul className="yellowBullet pl-0">
            <li>Register for MyEO experiences on the RIEMIX App</li>
            <li>All bookings are non-transferable and non-refundable.</li>
            <li>
              Transportation will be arranged from your respective hotels to
              each MyEO event.
            </li>
            <li>
              The timings mentioned for each event indicates departure time from
              the hotel until the end of the event. In case you are arriving at
              the event venue directly please let us know.
            </li>
            <li>
              Travel time to the venue can vary between 30 to 90 minutes
              depending on the event.
            </li>
            <li>
              Some evening MyEO events may be in conflict with the RIEMIX
              Welcome Dinner.
            </li>
            <li>
              {" "}
              In case you are unable to register for a MyEO event, please join
              the waitlist via the App.
            </li>
          </ul>
        </div>
      </div>
      <EventPopup
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        data={popupData}
        handleModalClose={handleModalClose}
      />
      <Footer />
    </>
  );
}
